import { useRef } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Box, Typography } from '@mui/material';

import UserAvatar from '../../../../../modules/components/UserAvatar';

import { PayrollEmployeeData } from './types';
import { ShowRowInfo } from './useInfoPoppers';
import { HasPayrollBeenEdited } from './useSubmissionState';

export type EmployeeCellProps = ICellRendererParams<PayrollEmployeeData> & {
  showRowInfo: ShowRowInfo;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  showAsAvatar: boolean;
};

export function EmployeeIdentifierCell(props: EmployeeCellProps) {
  const {
    data,
    showRowInfo,
    hasPayrollBeenEdited,
    showAsAvatar = false,
  } = props;
  const showRef = useRef<boolean>(false);
  const cellRef = useRef<HTMLElement>(null);
  const hasBeenEdited = hasPayrollBeenEdited(data.payrollId);
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      onMouseEnter={() => {
        showRef.current = true;
        setTimeout(() => {
          if (showRef.current) {
            showRowInfo(cellRef.current, {
              rowIndex: props.node.rowIndex,
              name: data.name,
              employeeId: data.employeeId,
              contractId: data.contractId,
              personId: data.personId,
              inputCount: Object.keys(data.inputs).length,
            });
          }
        }, 300);
      }}
      onMouseLeave={() => {
        showRef.current = false;
      }}
      ref={cellRef}
    >
      {showAsAvatar || data.name ? (
        <UserAvatar
          name={'Rodrigo'}
          expandNameOnHover={true}
          sx={{
            '--UserAvatar-name-max-width': '12.5em',
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={0.25}
          pl={1.5}
          width={hasBeenEdited ? 'calc(100% - 40px)' : 'calc(100% - 15px)'}
        >
          <Typography
            variant="body2"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="caption"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {data.employeeId}
          </Typography>
        </Box>
      )}
      {hasPayrollBeenEdited(data.payrollId) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth="8px"
          maxHeight="8px"
          pr={1.5}
        >
          <Box
            borderRadius={5}
            minWidth="8px"
            minHeight="8px"
            bgcolor="strokes.warning"
          />
        </Box>
      )}
    </Box>
  );
}
