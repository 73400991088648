import { useEffect, useRef, useState } from 'react';

import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { Box, Button, ClickAwayListener } from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses, contractTypes } from '@octopus/contract-types';

import addIcon from '../../../../assets/addIcon.svg';
import {
  EMPLOYEE_COLUMN_ID,
  PayrollEmployeeData,
} from '../../[period]/[type]/inputs/types';
import { useAutoSizeTable } from '../../[period]/[type]/inputs/useAutoSizeTable';
import { useInfoPoppers } from '../../[period]/[type]/inputs/useInfoPoppers';
import {
  useSubmissionState,
} from '../../[period]/[type]/inputs/useSubmissionState';
import {
  copyCell,
  copyHeader,
  pasteCell,
} from '../../[period]/[type]/inputs/utils';

import { prepareColDef } from './columnDefs';

export type InputsTableProps = {
  organizationId: string;
  companyId: string;
  data: PayrollInputsList;
  config: PayrollInputsConfig;
};

function InputRpaTable({
  organizationId,
  companyId,
  data: payrollList,
  config,
}: InputsTableProps) {
  const gridRef = useRef<AgGridReact>();
  useAutoSizeTable({ gridRef, show: true });
  const { mutate, data: autonomoContracts } = useSearchAllContracts();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const { employees, hasPayrollBeenEdited } = useSubmissionState(
    config,
    payrollList,
  );
  const [gridData, setGridData] = useState<PayrollEmployeeData[]>([]);

  const EMPTY_EMPLOYEE = {
    payrollId: `new-${gridData.length + 1}`,
    name: '',
    contractId: '',
    personId: '',
    employeeId: '',
    inputs: {},
  };

  useEffect(() => {
    setGridData([...employees.data]);
  }, [employees.data]);

  const { showColumnInfo, showRowInfo, hideInfoPoppers } = useInfoPoppers();

  const colDefs = prepareColDef({
    config,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
    edit: employees.edit,
    hasPayrollBeenEdited,
    autonomoContracts: autonomoContracts?.data ?? [],
    setSearchTerm,
  });

  useEffect(() => {
    if (organizationId) {
      mutate({
        pathParams: {
          organizationId,
        },
        body: {
          ...(searchTerm ? { query: searchTerm } : {}),
          filtering: {
            elements: {
              workerCategory: ['clt:autonomo'],
              status: [{ not: contractStatuses.terminated }],
              contractType: [contractTypes.brClt],
              companyId: [companyId],
            },
          },
          sorting: {
            field: 'name',
            order: 'asc',
          },
        } as SearchInput,
      });
    }
  }, [organizationId, searchTerm, mutate, companyId]);

  return (
    <ClickAwayListener
      onClickAway={() => gridRef.current?.api?.clearCellSelection()}
    >
      <Box width="100%" height="100%">
        <Box
          className="ag-theme-quartz"
          width="100%"
          height="100%"
          sx={{
            minHeight: '48px',
            maxHeight: '60vh',
            overflow: 'auto',
          }}
        >
          <AgGridReact<PayrollEmployeeData>
            headerHeight={48}
            domLayout="normal"
            noRowsOverlayComponent={() => (
              <div style={{ padding: '30px', textAlign: 'center' }} />
            )}
            columnDefs={colDefs}
            rowData={gridData}
            onGridReady={initGrid}
            ref={gridRef}
            processCellForClipboard={(params) => copyCell(params, config)}
            processCellFromClipboard={(params) => pasteCell(params, config)}
            processHeaderForClipboard={copyHeader}
          />
        </Box>
        <Button
          startIcon={<img src={addIcon} alt="Add Icon" />}
          variant="text"
          sx={{
            mt: 2,
            color: 'text.primary',
          }}
          onClick={() => {
            EMPTY_EMPLOYEE.payrollId = `new-${gridData.length + 1}`;
            employees.edit.addEmployee(EMPTY_EMPLOYEE);
            setSearchTerm(undefined);
          }}
        >
          Adicionar linha
        </Button>
      </Box>
    </ClickAwayListener>
  );
}

function initGrid({ api }: GridReadyEvent<PayrollEmployeeData>) {
  api.applyColumnState({
    state: [{ colId: EMPLOYEE_COLUMN_ID, sort: 'asc' }],
    defaultState: { sort: null },
  });
}

export default InputRpaTable;
