import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconChevronDown } from '@tabler/icons-react';

import {
  Box,
  Button,
  Container,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  PayrollInputsConfig,
  useGetPayrollInputsConfiguration,
} from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';
import {
  DataGridToolbar,
  FilterOptions,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import Pencil from '../../../assets/pencil.svg';
import { TrashIcon } from '../../../modules/components/autonomos/icon';
import { PageAlert } from '../../../modules/components/PageAlert';
import EnableMoreActionsPopover from '../../../modules/components/payrolls/moreActionsPopover';
import { DataFetching } from '../../../modules/dataFetching';
import { SubmissionToast } from '../[period]/[type]/SubmissionToast';

import createBaseRPAInputConfiguration from './inputs/createBaseRpaInputConfiguration';
import InputRpaTable from './inputs/inputRpaTable';
import { SelectPeriodDialog } from './RpaDialogs';

export default function RPALaunchPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { period } = useParams<{
    period: string;
  }>();
  const navigate = useNavigate();
  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [showCreateRPADialog, setShowCreateRPADialog] = useState(false);
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const moreActionsState: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  } = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  const editRPAMoreActions = [
    {
      label: 'Repetir autônomos da última competência',
      disabled: true,
    },
    {
      label: 'Colar uma lista de matrículas',
      disabled: true,
    },
    {
      label: '',
      divider: true,
    },
    {
      label: 'Remover todos autônomos',
      onClick: () => navigate(`/autonomos`),
      icon: TrashIcon(),
      disabled: true,
    },
  ];

  const filters = useFilters();
  const { filteringProps, searchProps } = useDataGrid({
    filters,
  });

  const inputsConfiguration = useGetPayrollInputsConfiguration(
    {
      pathParams: {
        organizationId,
        companyId,
      },
    },
    {
      enabled: !!organizationId && !!companyId,
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );

  useEffect(() => {
    if (
      inputsConfiguration.isSuccess &&
      Object.values(inputsConfiguration.data.payload).filter(
        (input) => input.target === 'rpa',
      ).length === 0
    ) {
      createBaseRPAInputConfiguration({ organizationId, companyId });
    }
  }, [inputsConfiguration, organizationId, companyId]);

  if (!organizationId || !companyId) {
    return (
      <Typography variant="h1">
        Selecione uma organização e companhia
      </Typography>
    );
  }
  // TODO use on Edit
  // const payrollInputs = useGetPayrollInputsForPeriod(
  //   {
  //     pathParams: {
  //       organizationId,
  //       companyId,
  //       periodId: period,
  //       payrollType: type,
  //     },
  //   },
  //   {
  //     enabled: !!organizationId && !!companyId && !!period && !!type,
  //     staleTime: Infinity,
  //     refetchOnMount: 'always',
  //   },
  // );

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar sx={{ padding: 0 }}>
        <Button
          variant="text"
          color="primaryAlt"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            padding: 0,
            height: '26px',
          }}
          onClick={() => navigate(`/autonomos`)}
        >
          Cancelar
        </Button>
      </Toolbar>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          data-testid="autonomo-page-header"
          px="40px"
        >
          <Typography variant="subtitle1" fontSize={'12px'}>
            Pagamentos para autônomos
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              height: '32px',
              '& img': {
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
              },
              '&:hover img': { opacity: 1 },
            }}
            onClick={() => setShowCreateRPADialog(true)}
          >
            <Typography variant="h1" fontSize={'24px'}>
              {formatPeriodDate(period)}
            </Typography>
            <Box
              component="img"
              src={Pencil}
              sx={{ height: '20px', width: '20px', padding: '0px' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            px: '40px',
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
          }}
        >
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            searchPlaceholder="Procurar"
          >
            <Box
              sx={{
                alignContent: 'end',
                flex: 'end',
                display: 'flex',
                gap: 1,
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                color="secondary"
                sx={{
                  display: 'flex',
                  width: 130,
                  height: 40,
                  p: 1,
                  m: 0,
                  gap: 1,
                }}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  moreActionsState.setPopOverAnchorEl(event.currentTarget);
                  moreActionsState.setEnableMoreActionsPopoverOpen(true);
                }}
              >
                <Typography
                  variant={'body2'}
                  color="primaryAlt"
                  fontWeight={400}
                >
                  Mais ações
                </Typography>
                <IconChevronDown height={'20px'} width={'20px'} />
              </Button>
            </Box>
            <EnableMoreActionsPopover
              moreActionsProps={moreActionsState}
              actions={editRPAMoreActions}
            />
            <SelectPeriodDialog
              open={showCreateRPADialog}
              setOpen={setShowCreateRPADialog}
              organizationId={organizationId}
              companyId={companyId}
            />
          </DataGridToolbar>
          <DataFetching<{
            inputsConfiguration: PayrollInputsConfig;
          }>
            containerSx={{ height: '100%' }}
            fetchResult={{
              results: {
                inputsConfiguration: {
                  ...inputsConfiguration,
                  isLoading:
                    inputsConfiguration.isLoading ||
                    !inputsConfiguration.isFetchedAfterMount,
                },
                // payrollInputs: {
                //   ...payrollInputs,
                //   isLoading:
                //     payrollInputs.isLoading || !payrollInputs.isFetchedAfterMount,
                // },
              },
            }}
            Loading={() => (
              <Skeleton
                variant="rounded"
                width="100%"
                height="calc(100vh - 245px)"
              />
            )}
            Data={() => {
              return (
                <InputRpaTable
                  organizationId={organizationId}
                  companyId={companyId}
                  data={{
                    organizationId,
                    companyId,
                    period,
                    type: 'rpa',
                    payrolls: {},
                  }}
                  config={inputsConfiguration.data}
                />
              );
            }}
            Error={() => {
              if (
                inputsConfiguration.error &&
                'statusCode' in inputsConfiguration.error &&
                inputsConfiguration.error.statusCode === 404
              ) {
                createBaseRPAInputConfiguration({ organizationId, companyId });
              }
              return <GenericError />;
            }}
          />

          <SubmissionToast />
        </Box>
      </Container>
    </Box>
  );
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor líquido',
      propertyToFilter: 'calculationTotals.netPay',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
  ].filter(Boolean);
}

function GenericError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Falha ao carregar tabela de lançamentos!"
        severity="error"
        showRetryMessage={true}
      />
    </Box>
  );
}
