import {
  ColDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';

import { ContractSummary, PayrollInputsConfig } from '@octopus/api';

import UserAvatar from '../../../../modules/components/UserAvatar';
import { EmployeeIdentifierCell } from '../../[period]/[type]/inputs/EmployeeIdentifierCell';
import { IdentifierHeader } from '../../[period]/[type]/inputs/IdentifierHeader';
import { newInputColDef } from '../../[period]/[type]/inputs/inputColDef';
import {
  ColumnInfo,
  EMPLOYEE_COLUMN_ID,
  PayrollEmployeeData,
  RowInfo,
} from '../../[period]/[type]/inputs/types';
import {
  HasPayrollBeenEdited,
  InputsEdit,
} from '../../[period]/[type]/inputs/useSubmissionState';

type ColDefProps = {
  config: PayrollInputsConfig;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
  showRowInfo: (ref: HTMLElement, info: RowInfo) => void;
  hideInfoPoppers: () => void;
  edit: InputsEdit;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  autonomoContracts: ContractSummary[];
  setSearchTerm: (searchTerm: string | undefined) => void;
};

export function prepareColDef({
  config,
  showColumnInfo,
  showRowInfo,
  hideInfoPoppers,
  edit,
  hasPayrollBeenEdited,
  autonomoContracts,
  setSearchTerm,
}: ColDefProps): ColDef<PayrollEmployeeData, string>[] {
  return [
    {
      colId: EMPLOYEE_COLUMN_ID,
      headerName: 'Colaborador',
      headerComponent: IdentifierHeader,
      cellRendererParams: {
        showRowInfo,
        hasPayrollBeenEdited,
        showAsAvatar: true,
      },
      cellRenderer: EmployeeIdentifierCell,
      editable: true,
      valueGetter: (params) => `${params.data.name}`,
      valueSetter: ({
        newValue,
        data: { payrollId },
      }: ValueSetterParams<PayrollEmployeeData, string>) => {
        console.log(newValue);
        edit.set(payrollId, 'name', newValue);
        return true;
      },
      singleClickEdit: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: (props: CustomCellRendererProps) => {
          console.log('values', props);
          return autonomoContracts?.map((contract) => contract.name) || [];
        },
        allowTyping: true,
        onSearch: (searchText: string) => {
          setSearchTerm(searchText);
        },
        searchDebounceDelay: 500,
        cellRenderer: (props: CustomCellRendererProps) => {
          return (
            <UserAvatar
              name={props?.value}
              expandNameOnHover={true}
              sx={{
                '--UserAvatar-name-max-width': '12.5em',
              }}
            />
          );
        },
      },
      flex: 1,
      pinned: 'left',
      sortable: true,
      resizable: true,
      suppressMovable: true,
      initialSort: 'asc',
      comparator: (a, b) => a.localeCompare(b),
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      minWidth: 220,
      maxWidth: 280,
      cellStyle: {
        padding: '0',
      },
    },
    ...Object.values(config.payload)
      .filter((entry) => entry.target === 'rpa')
      .sort((a, b) => a.tag.localeCompare(b.tag))
      .map((entry) =>
        newInputColDef({
          entry,
          showColumnInfo,
          hideInfoPoppers,
          hasBeenEdited: () => false, // TODO: change this when showing Edited
          valueGetter: ({
            data: { inputs },
          }: ValueGetterParams<PayrollEmployeeData, string>) =>
            inputs[entry.id],
          valueSetter: ({
            newValue,
            data: { payrollId },
          }: ValueSetterParams<PayrollEmployeeData, string>) => {
            edit.set(payrollId, entry.id, newValue);
            return true;
          },
        }),
      ),
  ];
}
